import { BackgroundStyleType, ElementTextBlockPositionType, PaletteColorType, TextStyleType, TrayType } from "common/constants";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";

import { CollectionElement } from "../../base/CollectionElement";
import { FixedGridLayout } from "./GridLayoutEngines/FixedGridLayout";
import { LayoutContainerItem } from "./LayoutContainerItem";
import { FlexGridLayout } from "./GridLayoutEngines/FlexGridLayout";
import { DashboardLayouts, HeadlineLayouts, PhotoCollageLayouts, SideBarLayouts, StackDiagramLayouts } from "./GridLayoutEngines/LayoutDefs";
import { PreserveAspectLayout } from "./GridLayoutEngines/PreserveAspectLayout";
import { GridLayoutContainerControlBar } from "../../../Editor/ElementPropertyPanels/GridContainer/GridLayoutUI";
import { HeadlineLayout } from "./GridLayoutEngines/HeadlineLayout";
import { SingleCellGridLayout } from "./GridLayoutEngines/SingleCellGridLayout";
import { Footer } from "../Footer";
import { CustomGridLayout } from "./GridLayoutEngines/CustomGridLayout";

export const LayoutEngineType = {
    FIXED: "fixed",
    FLEX: "flex",
    PRESERVE_ASPECT: "preserve-aspect",
    HEADLINE: "headline",
    DASHBOARD: "dashboard",
    SIDEBAR: "sidebar",
    BOTTOM_TRAY: "bottom_tray",
    SINGLE_CELL: "single_cell",
    CUSTOM: "custom",
    STACK_DIAGRAM: "stack_diagram"
};

const BottomTrayLayouts = [{
    cols: 60,
    rows: 2,
    cells: [{ x: 0, y: 0, width: 60, height: 2 }],
    favorite: true
}, {
    cols: 60,
    rows: 2,
    cells: [{ x: 0, y: 0, width: 30, height: 2 }, { x: 30, y: 0, width: 30, height: 2 }],
    favorite: true
}, {
    cols: 60,
    rows: 2,
    cells: [{ x: 0, y: 0, width: 20, height: 2 }, { x: 20, y: 0, width: 20, height: 2 }, { x: 40, y: 0, width: 20, height: 2 }],
    favorite: true
}, {
    cols: 60,
    rows: 2,
    cells: [{ x: 0, y: 0, width: 15, height: 2 }, { x: 15, y: 0, width: 15, height: 2 }, { x: 30, y: 0, width: 15, height: 2 }, { x: 45, y: 0, width: 15, height: 2 }],
    favorite: true
}, {
    cols: 60,
    rows: 2,
    cells: [{ x: 0, y: 0, width: 12, height: 2 }, { x: 12, y: 0, width: 12, height: 2 }, { x: 24, y: 0, width: 12, height: 2 }, { x: 36, y: 0, width: 12, height: 2 }, { x: 48, y: 0, width: 12, height: 2 }],
    favorite: true
}];

export class GridLayoutContainer extends CollectionElement {
    static get schema() {
        return {
            items: [{}]
        };
    }

    getElementControlBar() {
        return GridLayoutContainerControlBar;
    }

    getElementSelection() {
        return this.layoutEngine.getContainerSelection();
    }

    get name() {
        return "Grid";
    }

    get isLayoutContainer() {
        return true;
    }

    get maxItemCount() {
        return 10;
    }

    get allowDirectDoubleClickOnChildrenSiblings() {
        return true;
    }

    getChildItemType() {
        return LayoutContainerItem;
    }

    get defaultItemData() {
        return {
            componentType: "GridPlaceholder",
            childElement: {}
        };
    }

    get defaultTextStyle() {
        return TextStyleType.HEADING;
    }

    get defaultCellColor() {
        return PaletteColorType.BACKGROUND_ACCENT;
    }

    get layoutEngineType() {
        return this.model.layoutEngineType ?? LayoutEngineType.FLEX;
    }

    setLayoutEngineType(value) {
        this.model.layoutEngineType = value;

        if (value === LayoutEngineType.CUSTOM) {
            FlexGridLayout.fillEmptySlots(this);
            return;
        } else {
            this.setGridLayout(this.getLayoutEngine().getDefaultLayout(this.itemCount));
        }

        // reset the transforms of all images
        for (let item of this.itemElements.filter(item => item.childElement?.content)) {
            item.childElement?.content?.assetElement?.resetMediaTransform();
        }

        if (value == LayoutEngineType.PRESERVE_ASPECT) {
            this.model.fullBleed = false;
        }
    }

    getLayoutEngine() {
        switch (this.layoutEngineType) {
            case LayoutEngineType.PRESERVE_ASPECT:
                return new PreserveAspectLayout(this);
            case LayoutEngineType.FIXED:
                return new FixedGridLayout(this);
            case LayoutEngineType.DASHBOARD:
                return new FlexGridLayout(this, { layouts: DashboardLayouts });
            case LayoutEngineType.HEADLINE:
                return new HeadlineLayout(this, { layouts: HeadlineLayouts });
            case LayoutEngineType.SIDEBAR:
                return new FlexGridLayout(this, { layouts: SideBarLayouts });
            case LayoutEngineType.BOTTOM_TRAY:
                return new FlexGridLayout(this, { layouts: BottomTrayLayouts });
            case LayoutEngineType.SINGLE_CELL:
                return new SingleCellGridLayout(this);
            case LayoutEngineType.CUSTOM:
                return new CustomGridLayout(this);
            case LayoutEngineType.STACK_DIAGRAM:
                return new FlexGridLayout(this, { layouts: StackDiagramLayouts });
            case LayoutEngineType.FLEX:
            default:
                return new FlexGridLayout(this, { layouts: PhotoCollageLayouts });
        }
    }

    getDefaultLayout() {
        return {
            cols: 12,
            rows: 12,
            cells: [{ x: 0, y: 0, width: 12, height: 12 }]
        };
    }

    get gridLayout() {
        if (!this.model.gridLayout) {
            this.model.gridLayout = this.getDefaultLayout();
        }
        if (!this.model.gridLayout.cells) {
            this.model.gridLayout.cells = [{ x: 0, y: 0, width: 12, height: 12 }];
        }
        return this.model.gridLayout;
    }

    setGridLayout(layout) {
        this.model.gridLayout = {
            cols: layout.cols ?? 12,
            rows: layout.rows ?? 12,
            cells: _.cloneDeep(layout.cells),
            fitGridLayout: layout.fitGridLayout
        };
    }

    get allowText() {
        return true;
    }

    get preventDirectDoubleClickOnChildren() {
        return true;
    }

    get hideControlBarWhenTextSelected() {
        return false;
    }

    getChildOptions(model, index) {
        return {
            allowText: this.allowText,
            elementSelection: this.layoutEngine.getCellSelection(),
            elementPropertyPanel: this.options.itemPropertyPanel,
            placeholderPropertyPanel: this.options.placeholderPropertyPanel,
            placeholderDefaultOverlay: this.options.placeholderDefaultOverlay,
            canPasteImage: this.options.canPasteImage,
            canEdit: this.options.canEdit,
            hideControlBarWhenTextSelected: this.hideControlBarWhenTextSelected
        };
    }

    getAvailableTextIdeas() {
        return ["headline", "bullets", "body", "quote", "stat", "icontext", "cell-caption", "cell-title"];
    }

    get isTray() {
        return this.parentElement?.isInstanceOf("TrayContainer");
    }

    get showTitle() {
        return this.model.showTitle ?? false;
    }

    get preventTransitionDuringRender() {
        return true;
    }

    get showGutter() {
        return this.model.showGutter ?? false;
    }

    get gutterSize() {
        return 10;
    }

    get allowFullBleed() {
        return true;
    }

    get fullBleed() {
        if (!this.allowFullBleed) {
            return false;
        }
        if (this.aspectRatio == "fit") {
            return false;
        } else {
            return (this.options.fullBleed || this.model.fullBleed) ?? false;
        }
    }

    get frameStyle() {
        return this.model.frame ?? "none";
    }

    getCanvasMargins() {
        if (this.fullBleed) {
            let margins = { left: 0, right: 0, top: 0, bottom: 0 };
            if (this.canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.INLINE) {
                margins.bottom = 50;
            }
            return margins;
        } else {
            return super.getCanvasMargins();
        }
    }

    _loadStyles(styles) {
        let maskType = this.model.maskType;
        if (maskType) {
            styles.frame = {
                frameType: maskType,
            };
            if (this.isTray && this.parentElement.trayLayout == TrayType.RIGHT_TRAY || this.parentElement.trayLayout == TrayType.RIGHT_INLINE) {
                styles.frame.flipX = true;
            }
        }
    }

    _exportToSharedModel() {
        return { gridContent: this.model };
    }

    _importFromSharedModel(model) {
        return model.gridContent;
    }

    _build() {
        this.layoutEngine = this.getLayoutEngine();

        if (this.layoutEngineType !== LayoutEngineType.PRESERVE_ASPECT) {
            let defaultCellColor = _.last(this.itemCollection)?.cellColor ?? this.defaultItemData.cellColor;

            for (let i = this.itemCollection.length; i < this.gridLayout.cells.length; i++) {
                this.itemCollection.push({ ...this.defaultItemData, cellColor: defaultCellColor });
            }
            for (let i = this.gridLayout.cells.length; i < this.itemCollection.length; i++) {
                this.itemCollection.pop();
            }
        }

        this.buildItems();
    }

    _calcProps(props, options) {
        const { size } = props;

        const layoutProps = this.layoutEngine.calcCellProps(this, {
            size,
            gridLayout: this.gridLayout,
            outerGutter: (this.showGutter && this.fullBleed) ? (this.styles.outerGutter ?? this.gutterSize) : 0,
            innerGutter: this.showGutter ? (this.styles.innerGutter ?? 10) : 0
        });

        return {
            ...layoutProps,
            size
        };
    }

    // TODO Are these still needed?
    setItemCount(count) {
        if (count > this.itemElements.length) {
            for (let i = this.itemElements.length; i < count; i++) {
                this.addItem(this.defaultItemData);
            }
        } else if (count < this.itemElements.length) {
            for (let i = this.itemElements.length; i > count; i--) {
                this.deleteItem(this.itemElements[i - 1].id);
            }
        }
    }

    getAnimations() {
        const animations = this._getAnimations();
        animations.forEach(animation => animation.element = this);

        if (this.animateChildren) {
            const childElements = Object.values(this.elements).filter(element => !!element.calculatedProps);
            const unitedBounds = childElements.reduce((bounds, element) => bounds ? bounds.union(element.bounds) : element.bounds, null);

            const splitsCount = 100;

            const colWidth = unitedBounds.width / splitsCount;
            const rowHeight = unitedBounds.height / splitsCount;

            const sortedElements = [];
            for (const colIdx of _.range(0, splitsCount)) {
                for (const rowIdx of _.range(0, splitsCount)) {
                    const cellBounds = new geom.Rect(colIdx * colWidth, rowIdx * rowHeight, colWidth, rowHeight);
                    const elements = childElements.filter(element => element.bounds.intersects(cellBounds));
                    for (const element of elements) {
                        if (!sortedElements.includes(element)) {
                            sortedElements.push(element);
                        }
                    }
                }
            }

            for (const element of sortedElements) {
                animations.push(...element.getAnimations());
            }
        }

        if (this.disableAnimationsByDefault) {
            animations.forEach(animation => animation.disabledByDefault = true);
        }

        return animations;
    }

    _getBackgroundColor(forElement) {
        if (forElement && forElement instanceof Footer && this.fullBleed && !this.showGutter && this.itemCount == 1) {
            if (this.itemElements[0].childElement?.showImage) {
                return BackgroundStyleType.IMAGE;
            } else if (this.itemElements[0].model.cellColor) {
                return this.canvas.getTheme().palette.getColor(this.itemElements[0].model.cellColor);
            }
        }
        return super._getBackgroundColor(forElement);
    }

    _migrate_10_02() {
        if (this.model.text) {
            if (this.itemCollection.length === 1) {
                this.itemCollection[0].text = this.model.text;
            } else {
                this.itemCollection.push({
                    text: this.model.text
                });
            }
            delete this.model.text;
        }

        if (this.model.elementTextBlock) {
            this.itemCollection.push({
                componentType: "TextAndImage",
                cellColor: this.model.color ?? this.model.backgroundColor,
                childElement: {
                    text: this.model.elementTextBlock,
                    backdropPadding: 40,
                    textPosition: this.model.textAlign,
                    textAlign: this.model.textAlign,
                }
            });
            delete this.model.elementTextBlock;
        }

        if (this.itemCollection.length === 0) {
            this.addItem(this.defaultItemData);
        }

        for (let item of this.itemCollection) {
            if (!item.cellColor || item.cellColor === "auto") {
                item.cellColor = this.canvas.getSlideColor();
            }

            // some legacy models had frameType set which wasn't supported in v10 in a photogrid - but is supported in v11 - so we need to remove it
            delete item.childElement?.frameType;
        }

        if (this.model.frame === "light") {
            this.model.borderColor = "white";
            this.model.borderShadow = true;
        } else if (this.model.frame == "dark") {
            this.model.borderColor = "black";
        }

        let gridLayoutIndex = 0;

        if (!this.model.layoutEngineType) {
            this.model.layoutEngineType = this.model.aspectRatio === "fit" ? LayoutEngineType.PRESERVE_ASPECT : this.layoutEngineType;
        }

        let layoutEngine;
        if (this.model.aspectRatio === "fit") {
            layoutEngine = new PreserveAspectLayout(this);
            gridLayoutIndex = isNaN(parseInt(this.model.gridLayout)) ? 0 : parseInt(this.model.gridLayout);
        } else {
            // migration always uses PhotoCollageLayouts as the default
            layoutEngine = new FlexGridLayout(this, { layouts: PhotoCollageLayouts });
        }
        if (layoutEngine.layouts?.length) {
            const layouts = layoutEngine.layouts.filter(layout => layout.cells.length === this.itemCollection.length);

            gridLayoutIndex = isNaN(parseInt(this.model.gridLayout)) ? 0 : parseInt(this.model.gridLayout);

            // special case because we flipped the default layouts for 2 items so the first layout was more appropriate
            if (this.itemCollection.length == 2) {
                if (parseInt(this.model.gridLayout) == 1 || (this.model.layoutEngineType === LayoutEngineType.SIDEBAR && !this.model.gridLayout)) {
                    gridLayoutIndex = 0;
                } else if (!this.model.gridLayout || parseInt(this.model.gridLayout) == 0) {
                    gridLayoutIndex = 1;
                }
            }
            const layout = layouts[gridLayoutIndex] ?? _.last(layouts);
            this.setGridLayout(layout);
            return;
        }

        this.setGridLayout({ fitGridLayout: gridLayoutIndex });

        delete this.model.frame;
        delete this.model.aspectRatio;
    }
}

export const elements = {
    GridLayoutContainer
};

